import React from 'react'
import styled from "styled-components"
import Stack from "parsley-core/src/components/Stack/Stack"

const ContentDetail = props => (
	<Stack className={`${props.className}`}>
		{props.children}
	</Stack>
)

const ContentDetailStyle = styled(ContentDetail)`
  ul + h1 {
    margin-top:0;
  }
  p {
    max-width: ${props => (props.maxWidth ? props.maxWidth : '80ch')};
  }
	dt {
	  font-weight:bold;
	}
  dd, dt{
	margin-bottom:var(--s-3);
  }
  h2 + *,h3 + * {
	margin-top:var(--s-4);
  }
  & > ul {
    list-style:none;
  }
  & > ul > li {
    padding-left:var(--s2);
    position:relative;

    &::before {
      content:'';
      position:absolute;
      left:var(--s-4);
      top:var(--s-3);
      display:block;
      width:var(--s-3);
      height:var(--s-3);
      border-radius:50%;
      border:calc(.75*var(--s-5)) solid ${props => (props.borderColor ? props.borderColor : 'var(--color-primary)')};
      background-color:${props => (props.borderColor ? props.borderColor : 'var(--color-primary)')};
    }
  }
  & > ol {
    list-style: decimal;
    padding:0 0 0 var(--s1);

    & > li {
      margin: var(--border-medium) 0;

      &::marker {
        color:var(--color-primary);
      }
    }

  }
  form + * {
	margin-top:var(--s5);
  }
  h3 + table {
	margin-top:var(--s-1);
  }
  ul.bullet-lists li, ol.bullet-lists li, dl.bullet-lists dd{
    padding-left:var(--s2);
    position:relative;

    &::before {
      content:'';
      position:absolute;
      left:var(--s-4);
      top:var(--s-3);
      display:block;
      width:var(--s-3);
      height:var(--s-3);
      border-radius:50%;
      border:calc(.75*var(--s-5)) solid ${props => (props.borderColor ? props.borderColor : 'var(--color-primary)')};
      background-color:${props => (props.borderColor ? props.borderColor : 'var(--color-primary)')};
    }
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    thead {
      border-bottom: var(--border-medium) solid ${props => (props.borderColor ? props.borderColor : 'var(--color-secondary)')};

      th {
        font-weight: var(--font-weight-medium);
        padding-bottom: var(--s-3);
      }
    }

    tr {
      border-bottom: var(--border-thinnest) solid var(--color-border-light);
    }

    tr:last-child {
      border-bottom: none;
    }

    th, td {
      vertical-align: top;
      padding: var(--layout-space-support);
      @supports (var(--layout-space)) {
        padding: var(--layout-space);
      }

    }
  }
`

export default ContentDetailStyle