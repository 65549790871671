import React from 'react'
import styled from 'styled-components'

const Grid = props => (
    <div className={props.className}>
        {props.children}
    </div>
)

const GridContainer = styled(Grid)`
    display: grid;
    grid-gap: ${props => (props.gridGap ? props.gridGap : 'var(--s2)')};

    @supports (width: min(${props => (props.minWidth ? props.minWidth : '19em')}, 100%)) {
        grid-template-columns: repeat(${props => (props.autoType ? props.autoType : 'auto-fit')}, minmax(min(${props => (props.minWidth ? props.minWidth : '19em')}, 100%), 1fr));
    }

    &.grid-2 {
        @supports (width: min(25em, 100%)) {
            grid-template-columns: repeat(${props => (props.autoType ? props.autoType : 'auto-fit')}, minmax(min(25em, 100%), 1fr));
        }
    }

    &.grid-4 {
        @supports (width: min(10em, 100%)) {
            grid-template-columns: repeat(${props => (props.autoType ? props.autoType : 'auto-fit')}, minmax(min(10em, 100%), 1fr));
        }
    }

`

export default GridContainer