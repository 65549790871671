import React from "react"
import { BgImage } from 'gbimage-bridge'
import { getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import LayoutCenter from "parsley-core/src/components/Layout/LayoutCenter"
import Seo from "../Seo/Seo";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {graphql, useStaticQuery} from "gatsby";

const LayoutContent = props => {

	const { contentImage } = useStaticQuery(
		graphql`{
		  contentImage: file(relativePath: {eq: "content-start.jpg"}) {
			id
			childImageSharp {
			  gatsbyImageData(
				breakpoints: [400, 700, 1000, 1300, 1600, 2000]
				layout: FULL_WIDTH
				quality: 35
			  )
			}
		  }
	  }`
	)

	const bgImage = getImage(contentImage)

	return (
		<Container className={props.className}>
			<Seo title={props.title} description={props.description} />
			<Header />
			<MainContent id="main-content" tabIndex="-1">
				<BgImageStyle className={props.className} image={bgImage} backgroundColor="var(--color-tertiary-gradient-lr)">
					<LayoutCenter>
						<H1Style>{props.title}</H1Style>
					</LayoutCenter>
				</BgImageStyle>
				<LayoutCenterStyle>
					{props.children}
				</LayoutCenterStyle>
			</MainContent>
			<Footer />
		</Container>
	)
}

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`
const MainContent = styled.main`
    flex: 1 0 auto;
  background: var(--color-background-light-secondary);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2384d2f6' fill-opacity='0.2'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

`
const LayoutCenterStyle = styled(LayoutCenter)`
    margin-top:0;
	padding:var(--s6) var(--content-space-support) var(--s5);
	@supports (padding:var(--s6) var(--content-space) var(--s5)) {
		padding:var(--s6) var(--content-space) var(--s5);
	}
`

const BgImageStyle = styled(BgImage)`
  	padding:var(--s4);
  	box-shadow: var(--box-shadow-horizontal);
`

const H1Style = styled.h1`
	padding-bottom:var(--s3);
	margin-bottom:var(--s-3);
  	position:relative;
  
	&:after {
	  position:absolute;
	  bottom:0;
	  left:0;
	  width:10ch;
	  height:var(--s-5);
	  background:var(--color-primary);
	  content: ' '
	}
`

export default LayoutContent