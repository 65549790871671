import React from 'react'
import styled from 'styled-components'

const UnorderedList = props => (
    <ul className={props.className}>
        {props.children}
    </ul>
)

const UnorderedListStyle = styled(UnorderedList)`
    list-style:none;
    
    & li {
      padding-bottom:var(--s-4);
    }
    &.bullet-lists li{
        padding-left:var(--s2);
        position:relative;
        
        &::before {
            content:'';
            position:absolute;
            left:var(--s-4);
            top:var(--s-3);
            display:block;
            width:var(--s-3);
            height:var(--s-3);
            border-radius:50%;
            border:calc(.75*var(--s-5)) solid ${props => (props.borderColor ? props.borderColor : 'var(--color-primary)')};
            background-color:${props => (props.borderColor ? props.borderColor : 'var(--color-primary)')};
        }
    }
    
    &.tick-list li{
        padding-left:var(--s2);
        position:relative;
        
        &::after {
            content:'';
            position:absolute;
            left:0;
            top:var(--border-medium);
            display:block;
            width:var(--s0);
            height:var(--s0);
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 64 64' focusable='false' role='presentation' height='64px' width='64px' aria-labelledby='icon-tick' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle id='icon-tick'%3ETick icon%3C/title%3E%3Cpath fill='${props => (props.fill ? props.fill : 'currentColor')}' d='M21.33,57.82,0,36.53l5.87-5.87L21.33,46.09,58.13,9.36,64,15.23,21.33,57.82' /%3E%3C/svg%3E");
            background-repeat:repeat;
            background-position: 0 0;
            background-size: contain;
            background-color: transparent;
            font-size:var(--s0);
        }
        
    }
`

export default UnorderedListStyle