import React from "react"
import LayoutContent from "../../components/Layout/LayoutContent"
import {graphql} from "gatsby"
import MdxProvider from "parsley-core/src/components/MdxProvider/MdxProvider"
import ContentDetail from "parsley-core/src/components/ContentDetail/ContentDetail";
import Grid from "parsley-core/src/components/Grid/Grid";
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image"

const Services = ({ data }, props) => {
	const aboutContent = data.aboutContent

	return (
		<LayoutContent title="About" className={props.className}>
			<GridStyle gridGap="var(--s5)" className="grid-2">
				<ContentDetail>
					<H2Style>{aboutContent.frontmatter.title}</H2Style>
					<MdxProvider>{aboutContent.body}</MdxProvider>
				</ContentDetail>
				<div>
					<GatsbyImageStyle alt="" image={aboutContent.childrenFile[0].childImageSharp.gatsbyImageData} />
				</div>
			</GridStyle>
		</LayoutContent>
	)
}

const GridStyle = styled(Grid)`
  	margin-bottom:var(--s6);
`

const GatsbyImageStyle = styled(GatsbyImage)`
    max-width: calc(2 * var(--s-2) + 33.25em);
	border:var(--layout-space-support) solid var(--color-tertiary);
	@supports (border:var(--layout-space) solid var(--color-tertiary)) {
		border:var(--layout-space) solid var(--color-tertiary);
	}
	background:var(--color-tertiary);
`

const H2Style = styled.h2`
  padding-bottom:var(--s3);
  margin-bottom:var(--s1);
  position:relative;

  &:after {
    position:absolute;
    bottom:0;
    left:0;
    width:10ch;
    height:var(--s-5);
    background:var(--color-primary);
    content: ' '
  }

  & span {
    display:block;
    padding-top:var(--s-4);
  }
`

export default Services

export const pageQuery = graphql`  
  query {
  aboutContent: mdx(fileAbsolutePath: {regex: "/about.mdx/"}) {
    body
    frontmatter {
	  subTitle
      title
      image
    }
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [400, 520]
        	width:520
			layout: CONSTRAINED
			quality: 50
		)
      }
    }
  }
}
`