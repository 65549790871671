import React from 'react'
import UnorderedList from "../UnorderedList/UnorderedList"
import ContentDetail from "../ContentDetail/ContentDetail"
import { Link } from 'gatsby'
import { MDXProvider } from "@mdx-js/react"
import {MDXRenderer} from "gatsby-plugin-mdx";

const shortcodes = {UnorderedList, ContentDetail, Link}

const MdxProvider = ({ children }) => {
	return (
		<MDXProvider components={shortcodes}>
			<MDXRenderer>
				{children}
			</MDXRenderer>
		</MDXProvider>
	)
}

export default MdxProvider